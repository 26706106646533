.clip-bottom {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.ql-container {
  height: 200px !important;
}

.layout-top {
  margin-top: 70px;
}

.edit-button {
  top: -70px;
  left: -28px;
}

.no-data-img {
  height: 400px
}